// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    /*&::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }*/
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#blocIntro {
    display: flex;
    justify-content: space-evenly;
    padding: 100px 2%;
    background: #e3001b;
    align-items: center;
    p {
        font-weight: 300;
        font-size: $font-size-24;
        color: #fff;
    }
    p.last {
        padding-top: 25px;
    }
    li {
        text-transform: initial;
        color: #fff;
        font-weight: 300;
        font-size: $font-size-24;
        list-style: none;
        margin-bottom: 25px;
        padding-left: 45px;
        line-height: 1.25;
        background: url(../images/ico_eclair_blanc.png) no-repeat left;
    }
}

#blocBurst_01 {
    display: flex;
    position: relative;
    a {
        width: 50%;
        .bloc {
            background: #000;
            padding: 35px 2%;
            border: 3px solid #fff;
            transition: 0.5s;
            &:hover {
                background: #fff;
                border: 3px solid #000;
                transition: 0.5s;
                .texteIcon p {
                    color: #000;
                }
                .texteIcon img.logo {
                    display: none;
                }
                .texteIcon img.logoHover {
                    display: block;
                }
            }
            .texteIcon {
                display: flex;
                justify-content: center;
                img.logoHover {
                    display: none;
                }
                p {
                    font-size: $font-size-28;
                    font-weight: normal;
                    color: #fff;
                    line-height: 1.2;
                    padding-left: 10px;
                }
            }
        }
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 44%;
        background: url(../images/accueil_autoroute_20.png) no-repeat;
        width: 255px;
        height: 339px;
        z-index: 2;
    }
}

#blocBurst_02 {
    display: flex;
    border-bottom: 30px solid #e3001b;
    a {
        width: 50%;
        .bloc {
            background: #fff;
            border: 3px solid #000;
            transition: 0.5s;
            &:hover {
                background: #000;
                border: 3px solid #fff;
                transition: 0.5s;
                .texteIcon p {
                    color: #fff;
                }
                .texteIcon img.logo {
                    display: none;
                }
                .texteIcon img.logoHover {
                    display: block;
                }
            }

            .texteIcon {
                display: flex;
                justify-content: center;
                align-items: center;

                img.logoHover {
                    display: none;
                }
                p {
                    font-size: $font-size-28;
                    font-weight: normal;
                    color: #000;
                    line-height: 1.2;
                    padding-left: 10px;
                }
            }
        }
        .bloc.left {
            padding: 35px;
        }
        .bloc.right {
            padding: 4.5px;
        }
    }
}

.main-content {
    position: relative;
    .owl-theme {
        .custom-nav {
            position: absolute;
            top: 20%;
            left: 0;
            right: 0;
            .owl-prev, .owl-next {
                position: absolute;
                height: 100px;
                color: inherit;
                background: none;
                border: none;
                z-index: 100;
                i {
                    font-size: 2.5rem;
                    color: #cecece;
                }
            }
            .owl-prev {
                left: 0;
                top: 90px;
            }
            .owl-next {
                right: 0;
                top: 90px;
            }
        }
    }
}


/*******************************************************************************
* MÉCANIQUE CAMIONS & REMORQUES
*******************************************************************************/

#bloc50-50_mecanique {
    display: flex;
    border-bottom: 25px solid #e3001b;
    .blocBurst, .blocVideo {
        width: 50%;
    }
    .blocBurst {
        .blocBlanc {
            background: #fff;
            padding: 35px 2%;
            border: 3px solid #000;
            transition: 0.5s;
            &:hover {
                background: #000;
                border: 3px solid #fff;
                transition: 0.5s;
                .texteIcon p {
                    color: #fff;
                }
                .texteIcon img.logo {
                    display: none;
                }
                .texteIcon img.logoHover {
                    display: block;
                }
            }
                
            .texteIcon {
                display: flex;
                padding-left: 18%;
                img.logoHover {
                    display: none;
                }
                p {
                    font-size: $font-size-28;
                    font-weight: 600;
                    color: #000;
                    line-height: 1.2;
                    padding-left: 10px;
                }
            }
        }
        .bloc {
            background: #000;
            padding: 9% 2%;
            border: 3px solid #fff;
            transition: 0.5s;
            &:hover {
                background: #fff;
                border: 3px solid #000;
                transition: 0.5s;
                .texteIcon p {
                    color: #000;
                }
                .texteIcon img.logo {
                    display: none;
                }
                .texteIcon img.logoHover {
                    display: block;
                }
            }
            .texteIcon {
                display: flex;
                padding-left: 18%;
                img.logoHover {
                    display: none;
                }
                p {
                    font-size: $font-size-28;
                    font-weight: 600;
                    color: #fff;
                    line-height: 1.2;
                    padding-left: 10px;
                }
            }
        }
    }
    .blocVideo {
        display: flex;
        align-items: center;
        background: #000;
        img.img-responsive {
            min-width: 100%;
        }
    }
}


/*******************************************************************************
* SERVICES ROUTIERS
*******************************************************************************/

#blocIntro_services {
    display: flex;
    justify-content: space-evenly;
    padding: 100px 2%;
    background: #000;
    align-items: center;
    border-bottom: 25px solid #e3001b;
    p {
        font-weight: 300;
        font-size: $font-size-24;
        color: #fff;
    }
    p.last {
        padding-top: 25px;
    }
    li {
        text-transform: initial;
        color: #fff;
        font-weight: 300;
        font-size: $font-size-24;
        list-style: none;
        margin-bottom: 25px;
        padding-left: 45px;
        line-height: 1.25;
        background: url(../images/ico_eclair_blanc.png) no-repeat left;
    }
}

#blocBurst_rouge {
    display: flex;
    position: relative;
    a {
        width: 50%;
        .bloc {
            background: #e3001b;
            padding: 40px 2%;
            border: 3px solid #fff;
            transition: 0.5s;
            &:hover {
                background: #fff;
                border: 3px solid #000;
                transition: 0.5s;
                .texteIcon p {
                    color: #000;
                }
                .texteIcon img.logo {
                    display: none;
                }
                .texteIcon img.logoHover {
                    display: block;
                }
            }
            .texteIcon {
                display: flex;
                justify-content: center;
                align-items: center;
                img.logoHover {
                    display: none;
                }
                p {
                    font-size: $font-size-28;
                    font-weight: 600;
                    color: #fff;
                    line-height: 1.2;
                    padding-left: 10px;
                    margin: 0;
                }
            }
        }
    }
}

#blocImages {
    display: flex;
    border-bottom: 3px solid #fff;
    border-right: 3px solid #fff;
    border-left: 3px solid #fff;
    border-top: 3px solid #fff;
    .middle {
        border-left: 3px solid #fff;
        border-right: 3px solid #fff;
    }
}

#blocBurst_solo {    
    .bloc {
        background: #fff;
        padding: 40px 2%;
        border: 3px solid #000;
        transition: 0.5s;  
        .texteIcon {
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                font-size: $font-size-28;
                font-weight: 600;
                color: #000;
                line-height: 1.2;
                padding-left: 10px;
                margin: 0;
            }
        }
    }
}

/*******************************************************************************
* EMPLOI
*******************************************************************************/

#blocIntro_emploi {
    background: #e3001b;
    padding: 75px 5%;
    p {
        color: #fff;
        font-weight: 300;
        font-size: $font-size-24;
    }
}

#blocEmplois {
    padding: 100px 5%;
    display: flex;
    flex-wrap: wrap;
    background: #000;

    .bloc {
        width: 50%;
        padding-bottom: 125px;
        h3 {
            color: #fff;
            text-transform: initial;
            font-weight: bold;
            font-size: $font-size-30;
            padding-bottom: 15px;
        }
        p {
            color: #fff;
            font-weight: normal;
            font-size: $font-size-24;
        }
        p.succursale {
            text-transform: uppercase;
        }
        .texte {
            padding-top: 20px;
            width: 90%;
        }
        .btnEmploi .mybtn {
            background: #e3001b;
            padding: 25px 90px;
            border: none;
            color: #fff;
            font-size: $font-size-24;
        }
    }
    .section-form {
        .module-formulaire {
            display:none;
            padding: 0 0 50px 0; 
            margin-top: -70px;
        }
        h2.formtitre {
            color: #fff;
            padding-bottom: 5px;
            font-weight: bold;
        }
        input, textarea {
            width: 100%;
        }
        .btnFormEmploi {
            clear: both;
            padding-top: 35px;
        }
        .btnFormEmploi .mybtn2 {
            background: #e3001b;
            padding: 20px 50px;
            border: none;
            color: #fff;
            font-size: $font-size-20;
        }
    }
}

/*******************************************************************************
* RENDEZ-VOUS
*******************************************************************************/
#blocFormulaire {
    background: #000;
    border-bottom: 20px solid #e3001b;

    .section-form {
        padding: 25px 0;

        .form-info {
            overflow: hidden;
            padding-bottom: 100px;
        }
        .blocTitre {
            display: flex;
            padding-bottom: 30px;
            .logo {
                width: 20%;
            }
        }
        .blocTitre2 {
            display: flex;
            align-items: center;
            padding-bottom: 30px;
            .logo {
                width: 9%;
            }
        }
        h3 {
            color: #e3001b;
            font-weight: bold;
            font-size: $font-size-30;
        }
        .message {
            text-align: center;
            padding: 75px 0;
            h4 {
                color: #fff;
            }
        }
        .btn {
            background-color: #e3001b;
            color: #fff;
            border: none;
            border-radius: 2px;
            padding: 15px;
            font-size: 1rem;
            width: 100%;
            transition: all 0.2s ease-in;
            &:hover {
                color: #000;
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#bloc50-50_coordonnees {
    background: #000;
    padding: 100px 5%;
    display: flex;
    justify-content: space-around;

    h2 {
        color: #e3001b;
        font-weight: bold;
        font-size: $font-size-48;
        padding-bottom: 50px;
    }
    h3 {
        color: #e3001b;
        font-weight: 600;
        font-size: $font-size-36;
        text-transform: initial;
        padding: 100px 0 50px 0px;
    }
    p {
        color: #fff;
        font-weight: normal;
        font-size: $font-size-30;
        line-height: 1.3;
    }
    .blocHeure {
        display: flex;
        justify-content: space-between;
        padding-bottom: 100px;
    }
    .blocFinal {
        display: flex;
        padding-top: 50px;
        align-items: center;
    }
}



/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: 1630px) {

.slideshow-wrap .caption {
    top: 40px;
    left: 40%;
}
}

@media screen and (max-width: 1600px) {

.main-content .owl-theme .custom-nav .owl-next, .main-content .owl-theme .custom-nav .owl-prev {
    top: 70px;
}
}

@media screen and (max-width: 1550px) {
   
#blocBurst_01::before {
    left: 45%;
    background: url(../images/accueil_autoroute_20_m.png) no-repeat;
    width: 158px;
    height: 210px;
}
#bloc50-50_coordonnees .left-side, #bloc50-50_coordonnees .right-side {
    width: 40%;
}
}

@media screen and (max-width: 1440px) {

.slideshow-wrap .caption {
    top: 45px;
    left: 45%;
}
}

@media screen and (max-width: 1410px) {
   
#blocIntro p br {
   display: none;
}
#blocIntro .left-side {
    width: 50%;
}
nav.module-menu .menu-navbar .wrapper.right .main-menu > ul li > a {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
}

@media screen and (max-width: 1400px) {
.main-content .owl-theme .custom-nav .owl-next, .main-content .owl-theme .custom-nav .owl-prev {
    top: 60px;
}
#bloc50-50_coordonnees p {
    font-size: 1.4rem;
}
}

@media screen and (max-width: $size-md-max) {
   
.slideshow-wrap .caption {
    left: 50%;
}
nav.module-menu .menu-navbar {
    height: 90px;
}
nav.module-menu {
    padding-top: 90px;
}
}

@media screen and (max-width: 1024px) {

nav.module-menu .menu-navbar.nav-invisible {
    top: -100px;
}    
#blocBurst_01::before {
    display: none;
}
#blocBurst_02 a .bloc .texteIcon p, #blocBurst_01 a .bloc .texteIcon p{
    font-size: 1.2rem;
}
.main-content .owl-theme .custom-nav .owl-next, .main-content .owl-theme .custom-nav .owl-prev {
    top: 40px;
}
#bloc50-50_coordonnees p {
    font-size: 1.2rem;
}
}

@media screen and (max-width: $size-sm-max) {

#blocIntro {
    flex-direction: column;
}
#blocIntro .left-side {
    width: 100%;
}
#blocIntro .right-side ul {
    padding-top: 35px;
}
#blocBurst_02 a .bloc .texteIcon p, #blocBurst_01 a .bloc .texteIcon p {
    font-size: 1rem;
}
#blocBurst_02 a .bloc.right {
    padding: 15.5px;
}
#blocBurst_02 a .bloc.right p {
    margin: 0;
}
.main-content .owl-theme .custom-nav .owl-next, .main-content .owl-theme .custom-nav .owl-prev {
    top: 25px;
}
#blocIntro_services {
    padding: 100px 5%;
    flex-direction: column;
}
#blocIntro_services .right-side {
    padding-top: 30px;
}
#blocBurst_rouge {
    flex-direction: column;
}
#blocBurst_rouge a {
    width: 100%;
}
#blocImages {
    flex-direction: column;
}
#blocImages .middle {
    border-top: 3px solid #fff; 
    border-bottom: 3px solid #fff; 
    border-right: none; 
    border-left: none; 
}
#bloc50-50_mecanique {
    flex-direction: column;
}
#bloc50-50_mecanique .blocBurst, #bloc50-50_mecanique .blocVideo {
    width: 100%;
}
#bloc50-50_coordonnees {
    flex-direction: column;
}
#bloc50-50_coordonnees .left-side, #bloc50-50_coordonnees .right-side {
    width: 100%;
}
#bloc50-50_coordonnees .right-side {
    padding-top: 100px;
}
#bloc50-50_coordonnees h3 {
    padding: 50px 0 50px 0px;
}
}


@media screen and (max-width: $size-xs-max) {

nav.module-menu .menu-navbar {
    height: 95px;
}   
.slideshow-wrap .caption {
    width: 100%;
    top: 10%;
}
#blocIntro {
    padding: 50px 2%;
}
#blocBurst_01, #blocBurst_02 {
    flex-direction: column;
}
#blocBurst_01 a, #blocBurst_02 a {
    width: 100%;
}
#blocBurst_02 a .bloc .texteIcon p, #blocBurst_01 a .bloc .texteIcon p {
    font-size: 1.4rem;
}
.main-content .owl-theme .custom-nav .owl-next, .main-content .owl-theme .custom-nav .owl-prev {
    top: 60px;
}
#blocFormulaire .section-form .form-info {
    padding-bottom: 50px;
}
#blocFormulaire .section-form .message {
    padding-top: 55px;
}
#blocEmplois {
    flex-direction: column;
}
#blocEmplois .bloc {
    width: 100%;
}
#blocEmplois .bloc .texte {
    width: 100%;
}
}
